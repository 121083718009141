import { md5 } from "jquery.md5";

$("body").on("click", ".close-popup", function (event) {
  //$(".popup").removeClass("active");
  // $(this).parent().parent().removeClass("active");
  console.log('a');
  $(this).parents('.section--popup').removeClass("active");
  return false;
});

(function ($) {
  window.DnDMQS = {
    getAwardID: function (nhan) {
      var id = -1;
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
      ];
      for (var i = 0; i < maxAward; i++) {
        if (arr[i] == nhan) {
          id = i;
        }
      }
      return id;
    },

    handleData: function (responseData) {
      var Key128 = responseData.split("=");
      var _Data = Key128[1];

      var indexx = parseInt(_Data.charAt(127));
      var count_start = _Data.substr(indexx + 39, 4);

      var AC = _Data.substr(indexx + 32, 1); //Pos 32+, 1 Char;
      var _Code = _Data.substr(indexx + 33, 6);
      var _Encry1 = _Data.substr(indexx, 32);
      var _Encry2 = $.md5(_Encry1 + _Data.substr(indexx + 32, 1));

      //console.log(_Encry1 + " md5 " + _Encry2);

      // console.log(
      //   indexx + " ac=  " + AC + " _code: " + _Code + " count_: " + count_start
      // );

      var indexImg = this.getAwardID(AC);
      
      _CheckSO = Key128Bytesold;

      if (_CheckSO == _Data) {
        return false;
      }

      $.ajax({
        // post ok
        type: "POST",
        url: link_savedata,
        //dataType: 'json', //or HTML, JSON, etc.,
        //data: "Key32Bytes1=" + _Encry1 + "&Key32Bytes2=" + _Encry2,
        dataType: "json", //or HTML, JSON, etc.,
        data: {
           action: "save_data",
           data: "Key32Bytes1=" + _Encry1 + "&Key32Bytes2=" + _Encry2,
        },
        success: function (data) {
          Key128Bytesold = _Data;
          
          if (data.status === 1 && data.data === "ok") {
            if (indexImg >= 0) {
              // for( let i = 1 ; i<=indexImg+1; i++){
              //     let timeactive = i*800;

              //     setTimeout(function(){
              //         $(".reward-item").eq(i-1).addClass("active");

              //         setTimeout(function(){
              //             $(".reward-item").eq(i-1).removeClass("active");
              //         }, 800);
              //     }, timeactive);

              // }
              for (let i = 0; i < 9; i++) {
                let index = Math.floor(Math.random() * 10);

                let timeactive = i * 300;
                setTimeout(function () {
                  $(".reward-item").eq(index).addClass("active");
                  setTimeout(function () {
                    $(".reward-item").eq(index).removeClass("active");
                  }, 290);
                }, timeactive);
              }
              // win item after random
              setTimeout(function () {
                $(".reward-item").eq(indexImg).addClass("active");
              }, 2700);

           
              $("#popup-award img").attr(
                "src",
                domain_IMG + imagesAward[indexImg]
              );

              setTimeout(function () {
                $("#popup-award").addClass("active");
                $(".reward-item").eq(indexImg).removeClass("active");
                start = true;
              }, 3500);
            }
          } else {
              //console.log("xx_xx");
              alert(data.status);
          }
        },
        error: function (response) {
          debugger;
          alert("Something went wrong");
        },
      });
    },
    getData: function () {
      var that = this;
      $.ajax({
        type: "POST",
        url: link_getdata,
        //dataType: 'html', //or HTML, JSON, etc.
        dataType: "json",
        data: { action: "get_data" },

        success: function (response) {
          if (response.status === 1) {
            //Trừ lượt còn lại
            spoint =
              parseInt($(".spoint").html()) - 1 > 0
                ? parseInt($(".spoint").html()) - 1
                : 0;
            $(".spoint").html(parseInt(spoint));

            //Tăng lượt đã dùng
            // let sUsedPoint = parseInt($('.sTotalPoint').html()) - parseInt(spoint);
            // $('.sUsedPoint').html(sUsedPoint);

            that.handleData(response.data);
          } else {
            alert(response.data);
          }
        },
        error: function (response) {
          alert("Something went wrong"); // REQUIRE cần thiết kế thêm div thông báo
        },
      });
    },
    initMQS: function () {
      var that = this;
      card.click(function () {
        if (start) {
          var spoint = parseInt($(".spoint").html());
          $(".spoint").html(parseInt(spoint));
          selectItem = jQuery(this);
          start = false;
          that.getData();
        }
      });
    },
  };
  if ($(".msq").length > 0) {
    var maxAward = 11,
      domainKM = urldomainKM,
      domain_IMG =
        "https://img.zing.vn/products/gn/landing/2024-ga-con-di-hoc/src/main/images/f3/reward/",
      imagesAward = [
        "1.png",
        "2.png",
        "3.png",
        "4.png",
        "5.png",
        "6.png",
        "7.png",
        "8.png",
        "9.png",
        "10.png",
        "11.png",
      ],
      // link_getdata = domainKM + "playgame/getdata.html",
      // link_savedata = domainKM + "playgame/savedata.html",
      link_getdata = domainKM,
      link_savedata = domainKM,
      card = jQuery(".btn-quay-ngay"),
      // btn_next = jQuery(".tiep-tuc"),
      start = true,
      // selectView = jQuery(".giai"),
      popupAward = jQuery("#popup-award"),
      selectViewCount = jQuery("p.count span"),
      // textCode = jQuery("#textCode"),
      selectItem,
      _CheckSO = "",
      spoint = parseInt($(".spoint").html()),
      // sTotalPoint = parseInt($('.sTotalPoint').html()),
      Key128Bytesold = "",
      targetpick = "",
      targetnonepick = "";

    window.DnDMQS.initMQS();
  }
})(jQuery);

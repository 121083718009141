import "lazysizes";
import Scrollbar from 'smooth-scrollbar';
import BezierEasing from "bezier-easing";

// SCROLL
// -- Init
var scaleratio = $(window).outerHeight() / 1000;
var aniComplete = true;




if ($(".scrollContent").length > 0) {
  let frameSwipe = {
    total: 5 ,
    frames: ['.section--1', '.section--2', '.section--3', '.section--4', '.section--footer'],
    navigation: '.nav-top li',
    index: 0,
    isEndAnimate: true,
    animateDelay: 400,
    animateEndDelay: 400,

  }

  $(".scrollLink").on("click", function (e) {
    e.preventDefault();
    if (frameSwipe.isEndAnimate) {
      frameSwipe.isEndAnimate = false;
     
      $("html").animate({
        scrollTop: $($(this).data("scroll-link")).offset().top
      }, 600, function () {
        frameSwipe.isEndAnimate = true;
      });
    }
    $(".nav-left-list li a").removeClass("active");
    $(".nav-bottom-list li a").removeClass("active");
    
    $(this).addClass("active");
  })

  // if (!viewportIsMobile($("body").outerWidth())) {

  //   // scrollbars['root'] = Scrollbar.init($(".scrollContent")[0], {
  //   //   alwaysShowTracks: true,
  //   //   damping: vdamping
  //   // });

  // }
  // else {
  //   $(".scrollLink").each(function () {
  //     $(this).on("click", function (e) {
  //       e.preventDefault();
  //       $(".scrollLink").removeClass("active");
  //       $(this).addClass("active");
  //       if (aniComplete == true) {
  //         aniComplete = false;
  //         $("body,html").animate({
  //           scrollTop: $($(this).data("scroll-link")).offset().top
  //         }, 600, function () {
  //           aniComplete = true;
  //         });

  //       }
  //     })
  //   });
  // }

}


// import Swiper from 'Swiper';








$(document).ready(function () {
  let users;
  let url = $(".urlnumber").text();
  if ($(".urlnumber").length > 0) {
    $.ajax({
      url: url,
      dataType: "json",
      success: function (result) {
        //format string
        users = result.data;
        $(".top-title .user").html(users + " Gunners");
      },
      error: function (xhr, ajaxOptions, thrownError) {},
    });
  }
});
function activePoint(minNum, currentNum, maxNum, pointNum) {
  if (currentNum >= minNum) {
    var milesTones = [100000, 80000, 60000, 30000, 10000, 5000];
    var endPoint = 0;

    for (var i = 0; i < milesTones.length; i++) {
      if (currentNum >= milesTones[i]) {
        endPoint = pointNum - i;
        break;
      }
    }

    $(".list-dot li").removeClass("active");

    for (var i = 0; i < endPoint; i++) {
      let nthChild = i; // nhớ xài let
      var timedelay = (i + 1) * (1500 / endPoint);

      setTimeout(function () {
        $(".list-dot li:eq(" + nthChild + ")").addClass("active");
        if (nthChild == endPoint - 1) {
          $(".list-dot li:eq(" + nthChild + ") .char").addClass("hide");
        }
      }, timedelay);
    }
    if (endPoint == 1) {
      $(".line-frame").addClass("width-0");
    } else if (endPoint == 2) {
      $(".line-frame").addClass("width-1");
    } else if (endPoint == 3) {
      $(".line-frame").addClass("width-2");
    } else if (endPoint == 4) {
      $(".line-frame").addClass("width-3");
    } else if (endPoint == 5) {
      $(".line-frame").addClass("width-4");
    } else if (endPoint == 6) {
      $(".line-frame").addClass("width-5");
    } else if (endPoint == 7) {
      $(".line-frame").addClass("width-6");
    }
  }
}
